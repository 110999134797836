/* You can add global styles to this file, and also import other style files */
@import 'primeicons/primeicons.css';
@import 'assets/scss/variables';
* {
  font-family: 'Inter', sans-serif;
}
html,
body {
  padding: 0;
  border: 0;
  margin: 0;
}

.row-error {
  background-color: #ffeaef;
}

.copy-box-button {
  height: auto;
  width: auto;
  padding: 0.125rem;
}

rqa-table {
  .add-row {
    .p-button.p-button-text {
      color: $primaryColor !important;
    }
  }
}

// MAP STYLES
.map-container {
  width: 100%;
  position: relative;
  #map {
    width: 100%;
  }

  .latlngInfo {
    background: #fff;
    padding: 0.5rem;
  }
}

